import Questionnaire from './js/questionnaire.js';


document.addEventListener('DOMContentLoaded', () => {
  const appContainer = document.getElementById('app');
  const questionnaire = new Questionnaire(appContainer);

  // Initialize the questionnaire

  document.querySelector('#start').addEventListener('click', () => {
    questionnaire.createCard()
    //hide startscreen
  })



  //WORKAROUND FOR BROWSERS THAT DONT KNOW SVH (OLD SAFARI IOS)
  // Get the viewport height
  let vh = window.innerHeight;

  // Set the custom property value
  document.documentElement.style.setProperty('--vh', `${vh * 0.01}px`);

  // Update the height on window resize
  window.addEventListener('resize', () => {
    let vh = window.innerHeight;
    document.documentElement.style.setProperty('--vh', `${vh * 0.01}px`);
  });

});
