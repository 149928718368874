import { questionsContent } from './questionnaire-content.js';
import gsap from "gsap";

let debug = false;
if (window.location.hash === '#debug') {
  debug = true;
}


export default class Questionnaire {
    constructor(container) {
        this.container = container;
        this.currentQuestionIndex = 0;
        this.questions = questionsContent;
        this.pagination = 0;
        this.debugEl = document.querySelector('.debug')

        if(debug == true) {
            this.debugEl.classList.add('visible')
        }

        this.card = document.querySelector('.card');
        this.cardHead = document.querySelector('.card-head');
        this.cardMain = document.querySelector('.card-main');
        this.cardOptions = document.querySelector('.card-options');
        this.cardButtons = document.querySelector('.card-buttons');

        this.heartStartscreen = document.querySelector('.heart-startscreen');
        this.valuesStartscreen = document.querySelector('.values-startscreen');

        this.optionsOverlay = document.querySelector('.options-overlay');
        this.optionsOverlayContent = document.querySelector('.options-overlay-content');
        this.optionsOverlayClose = document.querySelector('.options-overlay-close');

        if(this.heartStartscreen) {
            this.animateHeartStartscreen()
            
            this.updateFont()
    
            setTimeout(() => {
                this.cardHead.classList.remove('hide-this-element');
                this.cardMain.classList.remove('hide-this-element');
                this.heartStartscreen.classList.add('hide-this-element');            
                this.valuesStartscreen.classList.add('hide-this-element');            
            }, 5000);
        }
    }


    animateHeartStartscreen() {
        let heartSettings = {
            affa: 0,
            wght: 0,
            ital: 0,
            craz: 0,
            rund: 0,
        };
    
        const updateFontVariation = () => {
            this.heartStartscreen.style.fontVariationSettings = `"wght" ${heartSettings.wght}, "affa" ${heartSettings.affa}, "ital" ${heartSettings.ital}, "craz" ${heartSettings.craz}, "rund" ${heartSettings.rund}`;
            this.valuesStartscreen.innerHTML = `
                GENERAL ${heartSettings.affa} <br> 
                BODY ${heartSettings.wght} <br> 
                ACTIVITY ${heartSettings.ital}  <br> 
                EXTRAVAGANCE ${heartSettings.craz}  <br> 
                SENSITIVITY ${heartSettings.rund}
            `;
        };

        gsap.to(heartSettings, {affa: 100,duration: 1,yoyo: true,repeat: 5,onUpdate: updateFontVariation});
        gsap.to(heartSettings, {wght: 100,duration: 1.25,yoyo: true,repeat: 5,onUpdate: updateFontVariation});
        gsap.to(heartSettings, {ital: 100,duration: 2.65,yoyo: true,repeat: 5,onUpdate: updateFontVariation});
        gsap.to(heartSettings, {craz: 100,duration: 2,yoyo: true,repeat: 5,onUpdate: updateFontVariation});
        gsap.to(heartSettings, {rund: 100,duration: 2.65,yoyo: true,repeat: 5,onUpdate: updateFontVariation});
    
    
    }

    createCard() {
        gsap.to(this.card, {
            opacity: 0,
            duration: 0.25,
            // stagger: 0.1,
            onComplete: () => {
                this.pagination++;
                this.card.classList.remove('startscreen')
                this.content = this.questions[this.pagination - 1];
        
                this.cardHead.innerHTML = `
                <p class="pagination">[${this.pagination}/${this.questions.length}]</p>
                <p class="topic">${this.content.topic}</p>
                <h2 class="question">${this.content.text}</h2>
                `;
        
                this.createCardOptions();
                this.createCardButtons();

                gsap.fromTo(this.card, {
                    opacity: 0,
                    duration: 0.25
                }, {
                    opacity: 1,
                })
            }
        });
    }

    createCardOptions() {
        this.cardOptions.innerHTML = '<span class="heart variable">+</span>';
        this.content.options.forEach((option) => {
            const optionButton = document.createElement('button');
            optionButton.classList.add('option-button');
            optionButton.textContent = option.text;
            optionButton.dataset.value = option.value;
            optionButton.addEventListener('click', () => this.setValue(option.value, optionButton, this.cardOptions));
            this.cardOptions.appendChild(optionButton);
            //image options
            if(option.img) {
                // console.log('has image');
                optionButton.innerHTML = `
                    <img src="${option.img}" class="option-image"/>
                `
                optionButton.classList.add('option-button--image');
            }
        });
    }

    createCardButtons() {
        this.cardButtons.innerHTML = '';
        const nextButton = document.createElement('button');
        nextButton.id = 'next-button';
        nextButton.classList.add('button-primary');
        nextButton.classList.add('disabled');
        nextButton.textContent = 'NEXT';
        nextButton.addEventListener('click', () => {
            if (this.pagination < this.questions.length) {
                this.createCard();
            } else {
                this.createEndCard();
            }
        });
        this.cardButtons.appendChild(nextButton);
    }

    createEndCard() {
        gsap.to(this.card, {
            opacity: 0,
            duration: 0.25,
            onComplete: () => {
                // this.cardHead.innerHTML = `
                // <p class="result variable">
                // Congratulations! It's me: your perfect type. I would love to have <mark data-options="0">${this.getOptionText(this.questions[0].value, 0)}</mark> with you! My figure is <mark data-options="1">${this.getOptionText(this.questions[1].value, 1)}</mark> just how you like it. For our first date I would love to invite you to <mark data-options="2">${this.getOptionText(this.questions[2].value, 2)}</mark>! I’m sure you’ll find me attractive, because when there is a party I'm <mark data-options="3">${this.getOptionText(this.questions[3].value, 3)}</mark>. And when your cat dies and you are sad, I'll <mark data-options="4">${this.getOptionText(this.questions[4].value, 4)}</mark>. You see, I'm just for you.
                // </p>
                // `
                this.cardHead.innerHTML = `
                <p class="result variable">
                    Congratulations! It's me: your perfect type. I would love to have <mark data-options="0">${this.getOptionText(this.questions[0].value, 0)}</mark> with you! My figure is <mark data-options="1">${this.getOptionText(this.questions[1].value, 1)}</mark>, just the way you like it. I’m living a <mark data-options="2">${this.getOptionText(this.questions[2].value, 2)}</mark> lifestyle, so I will complement your life in a perfect way. Also, I’m sure you’ll find me attractive, because my vibe is rather <mark data-options="3">${this.getOptionText(this.questions[3].value, 3)}</mark>. And when your cat dies and you are sad, I'll <mark data-options="4">${this.getOptionText(this.questions[4].value, 4)}</mark>. You see, I’m made for you. +
                </p>
                `

                this.cardHead.querySelectorAll('mark').forEach(mark => {
                    mark.addEventListener('click', (e) =>  {
                        this.createOptionOverlay(e.target, e.target.dataset.options)
                        this.cardHead.querySelectorAll('mark').forEach(mark => {
                            mark.classList.remove('active')
                        })
                        mark.classList.add('active')
                    })
                })

                this.cardOptions.innerHTML = '';
                this.cardButtons.innerHTML = '';

                // Create DOWNLOAD Button
                this.downloadButton = document.createElement('a');
                this.downloadButton.id = 'next-button';
                this.downloadButton.classList.add('button-primary');
                this.downloadButton.classList.add('disabled');
                this.downloadButton.textContent = 'DOWNLOAD.TTF';
                this.downloadButton.setAttribute('download', ''); // Set the download attribute
                // Define dynamic file path
                
                setTimeout(() => {
                    this.updateDownloadLink();
                    this.downloadButton.classList.remove('disabled');        
                }, 1100);

                this.cardButtons.appendChild(this.downloadButton);
                
                // Create the 'Info' button
                var infoButton = document.createElement('a');
                infoButton.className = 'button-secondary';
                infoButton.href = './info.html';
                infoButton.textContent = 'Info';
                infoButton.target = '_blank';

                // Create the 'Restart' button
                var restartButton = document.createElement('a');
                restartButton.className = 'button-secondary';
                restartButton.href = './index.html';
                restartButton.textContent = 'Restart';

                // Append buttons to the cardButtons element
                this.cardButtons.appendChild(infoButton);
                this.cardButtons.appendChild(restartButton);
                    

                gsap.fromTo(this.card, {
                    opacity: 0,
                    duration: 0.25
                }, {
                    opacity: 1,
                })
            }
        });
    }

    updateDownloadLink() {
        var dynamicFilePath = `https://whatsyourtype.de/your-type/Partner_affa-${this.questions[0].value}_wght-${this.questions[1].value}_ital-${this.questions[2].value}_craz-${this.questions[3].value}_rund-${this.questions[4].value}.ttf`;
        console.log(dynamicFilePath);
        this.downloadButton.href = dynamicFilePath;
    }

    getOptionText(questionValue, questionIndex) {
        const question = questionsContent[questionIndex];
        const selectedOption = question.options.find(option => option.value === questionValue);
        return selectedOption ? selectedOption.textEndCard : '';
    }


    createOptionOverlay(target, index) {
        this.optionsOverlayContent.innerHTML = "";
        this.questions[index].options.forEach((option) => {
            const optionButton = document.createElement('button');
            optionButton.classList.add('option-button');
            if(this.questions[index].value == option.value) {
                // this.optionsOverlayContent.querySelectorAll('.option-button').forEach(el => {
                //     el.classList.add('active');
                    
                // })
                optionButton.classList.add('active');
            }
            optionButton.textContent = option.textEndCard;
            optionButton.dataset.value = option.value;
            optionButton.addEventListener('click', () => {
                this.setValueEndCard(option.value, index)
                target.innerHTML = option.textEndCard
            });
            this.optionsOverlayContent.appendChild(optionButton);
        });

        this.optionsOverlay.classList.add('visible');

        this.optionsOverlayClose.addEventListener('click', () => {
            // this.optionsOverlay.classList.remove('visible')
            this.hideOptionOverlay()
        })
    }

    hideOptionOverlay() {
        this.optionsOverlay.classList.remove('visible');
        this.cardHead.querySelectorAll('mark').forEach(mark => {
            mark.classList.remove('active')
        })
    }


  setValue(newValue, button, buttonWrapper) {
    // console.log('Selected value:', newValue);
    this.cardButtons.querySelector('#next-button').classList.remove('disabled')

    // Update and animate the value with GSAP
    gsap.to(this.content, { value: newValue, duration: 1, onUpdate: () => this.updateFont() });
    buttonWrapper.querySelectorAll('button').forEach(btn => {
        btn.classList.remove('active');
        btn.classList.add('inactive');
    })
    button.classList.remove('inactive');
    button.classList.add('active')
  }

  setValueEndCard(newValue, questionIndex) {
    gsap.to(this.questions[questionIndex], { value: newValue, duration: 1, onUpdate: () => this.updateFont(), onComplete: () => this.updateDownloadLink() });
    this.hideOptionOverlay()
  }
  
  updateFont() {
    document.documentElement.style.setProperty('--font-affa', `${this.questions[0].value}`);
    document.documentElement.style.setProperty('--font-wght', `${this.questions[1].value}`);
    document.documentElement.style.setProperty('--font-ital', `${this.questions[2].value}`);
    document.documentElement.style.setProperty('--font-craz', `${this.questions[3].value}`);
    document.documentElement.style.setProperty('--font-rund', `${this.questions[4].value}`);

    if(debug == true) {
        this.debugEl.innerHTML = `
            affa: ${this.questions[0].value}<br>
            wght: ${this.questions[1].value}<br>
            ital: ${this.questions[2].value}<br>
            craz: ${this.questions[3].value}<br>
            rund: ${this.questions[4].value}<br>
        `
    }

    // document.querySelector('.card-head, .heart').style.fontVariationSettings = `"wght" ${this.questions[0].value}, "affa" ${this.questions[1].value}, "ital" ${this.questions[2].value}, "craz" ${this.questions[3].value}, "rund" ${this.questions[4].value}`

  }
  
  
}
