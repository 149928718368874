export const questionsContent = [
    {
        topic: 'General Information',
        text: 'At the moment I’m looking for...', 
        value: 0, 
        options: [
            {
                text: '...a one night stand',
                textEndCard: 'a one night stand',
                value: 100,
            },
            {
                text: '...a friendship with benefits',
                textEndCard: 'a friendship with benefits',
                value: 75,
            },
            {
                text: '...nothing specific, I’ll see what happens',
                textEndCard: 'nothing specific',
                value: 50,
            },
            {
                text: '...a relationship',
                textEndCard: 'a relationship',
                value: 25,
            },
            {
                text: '...someone I can grow old together with, someone who loves me and has my back through the highs and lows of life. My save haven.',
                textEndCard: 'a lifelong relationship',
                value: 0,
            },
        ]
    },
    {
        topic: 'Appearance',
        text: 'The body of my future partner should be...', 
        value: 25, 
        options: [
            {
                text: '...delicate and graceful',
                textEndCard: 'delicate and graceful',
                value: 0,
            },
            {
                text: '...slender',
                textEndCard: 'slender',
                value: 25,
            },
            {
                text: '...average',
                textEndCard: 'average',
                value: 50,
            },
            {
                text: '...stable. I would like to have someone to lean on to',
                textEndCard: 'stable',
                value: 75,
            },
            {
                text: '...very strong and muscular',
                textEndCard: 'muscular',
                value: 100,
            },
        ]
    },
    {
        topic: 'Activity Level',
        text: 'How active should your partner be?', 
        value: 0, 
        options: [
            {
                text: '',
                textEndCard: 'not very active',
                img: 'https://whatsyourtype.de/static/q-wght-1.jpg',
                value: 0,
            },
            {
                text: '',
                textEndCard: 'quite active',
                img: 'https://whatsyourtype.de/static/q-wght-2.jpg',
                value: 50,
            },
            {
                text: '',
                textEndCard: 'very active',
                img: 'https://whatsyourtype.de/static/q-wght-3.jpg',
                value: 100,
            },
        ]
    },
    {
        topic: 'Extravagance',
        text: 'How is your vibe?', 
        value: 0, 
        options: [
            {
                text: '',
                textEndCard: 'natural',
                img: 'https://whatsyourtype.de/static/q-crazy-1.jpg',
                value: 0,
            },
            {
                text: '',
                textEndCard: 'fun',
                img: 'https://whatsyourtype.de/static/q-crazy-2.jpg',
                value: 50,
            },
            {
                text: '',
                textEndCard: 'expressive',
                img: 'https://whatsyourtype.de/static/q-crazy-3.jpg',
                value: 100,
            },
        ]
    },
    {
        topic: 'Sensitivity Level',
        text: 'Your cat dies all of a sudden. Your future partner...', 
        value: 0, 
        options: [
            {
                text: '...immediatly begins to dig a grave for it',
                textEndCard: 'immediatly dig a grave for it',
                value: 0,
            },
            {
                text: '..reminds me of the beautiful time we had together',
                textEndCard: 'remind you of the beautiful time',
                value: 25,
            },
            {
                text: '...comforts me, hugs me and cooks my favorite meal',
                textEndCard: 'cook your favorite meal',
                value: 50,
            },
            {
                text: '...is very, very sad and builds a little shrine with pictures of my cat.',
                textEndCard: 'build a shrine with pictures',
                value: 75,
            },
            {
                text: 'It seems like my partner is even more emotional about my cats death than I am. ',
                textEndCard: 'be even more sad than you',
                value: 100,
            },
        ]
    },
];